@import url("https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap");

body {
  /* width: 100%; */
  padding: 0;
  background-image: url("https://www.toptal.com/designers/subtlepatterns/patterns/debut_light.png");

}



.nav {
  display: inline-flex;
  position: relative;
  background-color: #a4a7c2;
  padding: 0;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  height: 100px;
}

.nav-item {
  color: white;
  padding: 20px;
  text-decoration: none;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  position: relative;
  font-size: 25px;
  margin-top: 10px;
  cursor: pointer;
}

.nav-item:not(.is-active):hover:before {
  opacity: 1;
  bottom: 0;
}

.nav-item:not(.is-active):hover {
  color: #333;
}

.nav-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  transition: 0.4s;
  height: 5px;
  z-index: 1;
  border-radius: 8px 8px 0 0;
}

@media (max-width: 580px) {
  .nav {
    overflow: auto;
  }
}

/* search */
.search-div {
  margin-left: 67%;
  margin-top: 14px;
}

.search-input {
  border-radius: 30px;
  width: 220px;
  height: 50px;
  border: none;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px;
  padding-left: 15px;
  background-color: rgb(232, 237, 241);
}

.search-button {
  position: absolute;
  left: 15px;
  height: 40px;
  width: 40px;
  padding: 0;
  margin-left: 94%;
  margin-top: 7px;
  border: none;
  background: none;
  outline: none !important;
  cursor: pointer;
}

/* shop cards */
.shop-card {
  align-items: center;
  background-color: #fdf5f6;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  display: grid;
  border-radius: 10px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px;
}

.shop-small-cards {
  display: flex;
  margin: 20px;
  width: 80%;
  height: 150px;
  background-color: #d6d9e7;
  cursor: pointer;
  margin-left: 10%;
}

.shop-small-cards:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
}

.image-shop-card {
  background-color: white;
  width: 80px;
  height: 120px;
  margin: 20px;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
  text-align: center;
}

.image-text {
  padding-top: 3px;
}

.book-image {
  max-width: 100%;
  max-height: 100%;
}

.shop-name {
  position: absolute;
  background-color: white;
  width: fit-content;
  height: 30px;
  margin: 10px;
  margin-left: 150px;
  margin-top: 30px;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
  border-radius: 5px;
}

.shop-name-text {
  padding-left: 5px;
  margin: 0;
  padding-right: 30px;
  font-size: 20px;
}

.shop-price-text {
  margin-left: 1220px;
  margin-top: 7%;
  font-weight: bold;
  font-size: 25px;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}

.shop-author {
  position: absolute;
  background-color: white;
  width: fit-content;
  height: 30px;
  margin-left: 150px;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
  border-radius: 5px;
  margin-top: 70px;
}

/* Add  */

.add-card {
  display: flex;
  margin: 20px;
  width: 50%;
  height: 600px;
  background-color: #d6d9e7;
  margin-left: 25%;
  box-shadow: 15px 51px 45px -35px rgba(0, 0, 0, 0.2);
}

.add-author,
.add-price,
.add-title {
  border: none;
  background-color: white;
  height: 60px;
  margin-top: 140px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  padding: 10px;
  margin-left: 30px;
  border-radius: 20px;
}

.add-author {
  margin-left: 100px;
  margin-right: 10px;
}

.add-details {
  position: absolute;
  margin-top: 260px;
  margin-left: 100px;
  height: 150px;
  width: 630px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  padding: 10px;
  border-radius: 20px;
}

.button-add {
  position: absolute;
  height: 50px;
  width: 170px;
  margin-left: 580px;
  margin-top: 450px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  background-color: white;
  border-radius: 20px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.add-details:hover,
.add-author:hover,
.add-price:hover,
.add-title:hover,
.button-add:hover {
  box-shadow: -10px 8px 0px rgb(196 181 253);
}

.add-details:focus,
.add-author:focus,
.add-price:focus,
.add-title:focus {
  box-shadow: -10px 8px 0px rgb(196 181 253);
}

.details-card {
  display: flex;
  margin: 20px;
  width: 80%;
  height: 600px;
  background-color: #d6d9e7;
  margin-left: 10%;
  box-shadow: 15px 51px 45px -35px rgba(0, 0, 0, 0.2);
}

.details-title {
  border: none;
  background-color: white;
  height: 40px;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  padding: 10px;
  padding-left: 30px;
  padding-top: 20px;
  margin-left: 30px;
  border-radius: 20px;

  min-width: 70px;
}
.details-title:hover,
.details-price:hover,
.details-small-card2:hover {
  box-shadow: 10px -8px 0px rgb(198, 207, 235);
}
.details-small-card1 {
  display: flex;
  margin-left: 500px;
}

.details-small-card2 {
  position: absolute;
  border: none;
  background-color: white;
  height: 350px;
  width: 900px;
  margin-top: 120px;
  margin-left: 300px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
}

.details-price {
  position: absolute;
  border: none;
  background-color: white;
  height: 30px;
  margin-top: 500px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  padding: 10px;
  padding-left: 30px;
  padding-top: 20px;
  margin-left: 1100px;
  border-radius: 20px;
  width: 70px;
}

.image-details {
  background-color: #dac7c7;
  height: 320px;
  width: 500px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
}

.content-details {
  margin-left: 10px;
  margin-top: 10px;
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  font-weight: 500;
  background-color: #d6d9e7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}

.left-arrow {
  position: absolute;
  margin-top: 250px;
  margin-left: 210px;
  font-size: 70px;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  margin-top: 250px;
  margin-left: 1250px;
  font-size: 70px;
  cursor: pointer;
}

.search-input-bar {
  margin-left: 60%;
  align-self: center;
}
/* .search-bar {
  border-radius: 10%;
  width: 120%;
  height: 200%;
} */
.example input[type="text"] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f1f1f1;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

form {
  color: #555;
  display: flex;
  padding: 2px;
  /* border: 1px solid currentColor; */
  border-radius: 5px;
  margin: 0 0 30px;
  flex-direction: column;

}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  align-self: baseline;
  background-color: white;
}

input[type="search"]::placeholder {
  color: #bbb;
}

button.search-btn[type="submit"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

button.search-btn:hover {
  opacity: 1;
}

button.search-btn:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}

form.nosubmit {
  border: none;
  padding: 0;
}

